import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import { HomePage } from './components/HomePage';
import { AboutPage } from './components/AboutPage';
import { ErrorBoundary } from 'react-error-boundary'


const App = () => {
  const router = createBrowserRouter([
    {
      id: 'root',
      path: '/',
      element: <Layout />,
      errorElement: <div>Something went wrong. This usually means a bad URL.</div>,
      children: [
        {
          index: true,
          element: <HomePage />
        },
        {
          path: 'about',
          element: <AboutPage />
        }
      ]
    }
  ])

  return (
    <div className="App">
      <ErrorBoundary fallback={<div>Something went wrong.</div>}>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </div>
  );
}

export default App;
