import { Outlet, Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { useState } from "react";

const KinkyAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = !!anchorEl

  const handleClose = () => setAnchorEl(null)
  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MenuIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
          <MenuItem onClick={handleClose}><Link to="/" style={{color: 'black', textDecoration: 'none'}}>Home</Link></MenuItem>
          <MenuItem onClick={handleClose}><Link to="/about" style={{color: 'black', textDecoration: 'none'}}>About</Link></MenuItem>
        </Menu>
        <Typography>Kinky Spice</Typography>
      </Toolbar>
    </AppBar>
  )
}

export const Layout = () => {
  return (
    <>
      <KinkyAppBar />
      <Box>
        <Outlet />
      </Box>
      <Box component="section" sx={{position: 'fixed', bottom: 0, width: '100%'}}>
        &copy; 2024 Kinky Spice
      </Box>
    </>
  )
}